import React from 'react';
import Pagination from 'components/Pagination';
import CandidatesFilters from '../../components/CandidatesFilters';
import PageLoader from 'components/PageLoader';
import PaginationInfo from 'components/PaginationInfo';
import CandidateCard from '../../components/CandidateCard';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetCandidatesQuery } from 'api/canidates.api';
import { useParams } from '../../../../../../../hooks/useParams';
import CandidatePositionInfo from '../../components/CandidateCard/components/CandidatePositionInfo';
import CandidatesEmptyState from 'router/subrouters/Dashboard/components/CandidateEmptyStateContainer/components/CandidatesEmptyState';
import { Candidate } from 'models/Candidate';
import { ApplicationSchoolStatus } from 'searchality-data';
import { getCandidateType } from '../utils';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import SortSelect from 'components/SortSelect';
import useSortByOptions from '../../hooks/useSortByOptions';

import './CandidatesWrapper.styles.scss';

const initialParams = {
  $limit: 6,
  $populate: ['schools', 'vacancy'],
};

const typeBaseParams: any = {
  new: {
    $where: {
      statusInSchool: {
        $in: [ApplicationSchoolStatus.NEW],
      },
      isArchived: undefined,
    },
  },
  qualified: {
    $where: {
      statusInSchool: {
        $in: [ApplicationSchoolStatus.IN_PROCESS],
      },
      isArchived: undefined,
    },
  },
  archived: {
    $where: {
      statusInSchool: {
        $in: [ApplicationSchoolStatus.REJECTED],
      },
      isArchived: { $in: true },
    },
  },
  hired: {
    $where: {
      statusInSchool: {
        $in: [ApplicationSchoolStatus.ACCEPTED],
      },
    },
  },
};

const initialFiltersParams = {};

const CandidatesWrapper: React.FC = () => {
  const { pathname } = useLocation();

  const type = getCandidateType(pathname);

  const {
    params,
    sortBy,
    filterBy,
    changeCurrentPage,
    resetFilters,
    addWhere,
  } = useParams({ ...initialParams, ...typeBaseParams[type] });

  const { t } = useTranslation();

  const navigate = useNavigate();

  const sortByOptions = useSortByOptions(pathname.includes('hired'));

  const { isLoading, data } = useGetCandidatesQuery(params);

  useEffectSkipFirst(() => {
    const { $where } = typeBaseParams[getCandidateType(pathname)];

    addWhere($where);
  }, [addWhere, pathname]);

  if (isLoading) {
    return <PageLoader />;
  }

  const { items: candidates, ...pagination } = data;

  const { $where, $sort } = params;

  const schoolId = $where?.['schoolIds']?.['$all']?.[0];

  const renderName = (candidate: Candidate) =>
    candidate?.schools?.find(({ _id }) => schoolId === _id)?.name;

  return (
    <div>
      <CandidatesFilters
        onFilter={filterBy}
        filteredBy={$where}
        onReset={resetFilters}
        filterParams={initialFiltersParams}
      />
      <div className="candidates-wrapper__pagination-info-wrapper">
        <PaginationInfo
          name={t('candidatesSmall')}
          {...pagination}
          limit={params?.$limit}
        />
        <SortSelect
          options={sortByOptions}
          value={$sort}
          placeholder={t('sortBy')}
          multiple={false}
          onChange={({ value }) => {
            sortBy(value);
          }}
        />
      </div>
      {!candidates?.length ? (
        <CandidatesEmptyState />
      ) : (
        <ul>
          {candidates.map((candidate) => {
            return (
              <CandidateCard
                key={candidate._id}
                {...candidate}
                onClick={() => navigate(`../${candidate._id}`)}
                haveActions
                suffixNode={
                  <CandidatePositionInfo
                    schoolName={
                      schoolId
                        ? renderName(candidate)
                        : candidate?.schools?.[0]?.name
                    }
                    positionTitle={candidate.vacancy?.positionTitle}
                  />
                }
              />
            );
          })}
        </ul>
      )}
      {pagination?.totalPages > 1 && (
        <Pagination {...pagination} onChange={changeCurrentPage} />
      )}
    </div>
  );
};

export default CandidatesWrapper;
