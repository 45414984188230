import React from 'react';
import classNames from 'classnames';
import PhotoPreview from 'components/PhotoPreview';

import './MemberInfo.styles.scss';

type MemberInfoProps = {
  className?: string;
  src?: string;
  backgroundColor?: string;
  placeholderText?: string;
  name?: string;
  email: string;
  onClick: () => void;
};

const MemberInfo: React.FC<MemberInfoProps> = (props) => {
  const {
    className,
    placeholderText,
    src,
    backgroundColor,
    name,
    email,
    onClick = () => {},
  } = props;

  const baseClass = 'member-info';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes} onClick={onClick}>
      <PhotoPreview
        placeHolderText={placeholderText}
        size="m"
        src={src}
        backgroundColor={backgroundColor}
      />
      <div className={`${baseClass}__text`}>
        {name && <p className={`${baseClass}__text__name`}>{name}</p>}
        <p className={classNames(`${baseClass}__text__email`)}>{email}</p>
      </div>
    </div>
  );
};

export default MemberInfo;
